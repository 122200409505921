<template>
  <div>
    <v-autocomplete :label="label" dense :dark="$store.state.isDarkMode" :rounded="rounded" item-text="name"
      item-value="id" full-width hide-details :solo="solo" :outlined="outlined" :flat="falt" :items="itemsVal"
      :clearable="clearable" :value="value" :return-object="returnObject" :loading="loading" :type="type"
      v-debounce="autoCompleteFn" @keydown.enter="$emit('keydown')" @input="$emit('input', $event)"></v-autocomplete>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    label: {
      type: String,
    },
    value: {
      type: [Object, String, Number],
    },
    endPoint: {
      type: String,
    },
    itemsList: {
      type: Array,
      default: () => [],
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    cash: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      itemsVal: [],
      loading: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        if (v && typeof v == "object") {
          this.itemsVal = [{ ...v }];
        }
      },
    },
  },
  methods: {
    async autoCompleteFn(value) {
      console.log(this.endPoint)
      try {
        if (!value) return;
        this.loading = true;
        let params = {
          name: value,
          query: value,
          cash: this.cash,
        };
        if (this.endPoint == '/student/auto-complete') {
          if (this.type == 'regRecord') { }
          else {
            params = {
              name: value,
              query: value,
              type: "full_name",
            };
          }

        }
        //   if(this.itemsList != []) {
        //   this.itemsVal = this.itemsList;
        //  }
        // else{
        const searchResult = await axios.get(this.endPoint, {
          params: params,
        });
        if (this.endPoint == '/user/auto-complete') this.itemsVal = searchResult.data.users;
        else if (this.endPoint == '/test') this.itemsVal = searchResult.data.tests;
        else if (this.endPoint == '/edu-class') this.itemsVal = searchResult.data.classes;
        else if (this.endPoint == '/grade') this.itemsVal = searchResult.data.grades;
        else if (this.endPoint == '/grade-type') this.itemsVal = searchResult.data.grade_types;
        else if (this.endPoint == '/educational-year') this.itemsVal = searchResult.data.educational_years;
        else if (this.endPoint == '/terms') this.itemsVal = searchResult.data.terms;
        else if (this.endPoint == '/subject') this.itemsVal = searchResult.data.subjects;
        else this.itemsVal = searchResult.data.data;
        //  }
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
